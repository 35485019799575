import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import color from '../constants/colors';

export default createGlobalStyle`
  ${normalize}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  font-size: 62.5%;
  body {
    font-size: 1.6rem;
    font-family: 'Maven Pro', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: ${color.white};
  }
}

img {
  width: 4rem; 
}

p{
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  line-height: normal;
}


li {
  list-style: none;
}

::selection {
  background-color: ${color.black};
  color: ${color.white};
}

:focus {
  outline: 0.1em dotted ${color.grey};
  outline-offset: 0;
  border-radius: 2rem;

}

button {
  cursor: pointer
}

a{
  color: ${color.orange};
  text-decoration: underline;
  cursor: pointer;
  
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2rem rgba(0, 0, 0, 0.3);
  border-radius: 3rem;
  background-color: ${color.white};
}

::-webkit-scrollbar {
  width: 0.5rem;
  background-color: ${color.white};
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 2rem rgba(0, 0, 0, .3);
  background-color: ${color.grey};
}
`;
