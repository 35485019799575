export default {
  white: '#fff',
  highTransparent: 'rgb(255, 255, 255, 0.9)',
  lowTransparent: 'rgb(255, 255, 255, 0.5)',
  black: '#000',
  orange: '#f47b32',
  lightOrange: '#ff811b',
  grey: '#414141',
  lightGrey: '#ececec',
};
